import { useAppContext } from '@/app/contexts/AppContext';
import { ErrorPage } from '@/components/common/error/ErrorPage';
import { Container } from '@/components/common/layout/Container';
import { Header } from '@/components/common/layout/Header';
import { Sidebar } from '@/components/common/layout/Sidebar';
import { APP_VERSION } from '@/config/constants';
import { useOrderformFlags } from '@/hooks/helpers';
import { useAppSelector } from '@/hooks/redux';
import { useAuth } from '@/store/auth/authHooks';
import { selectMaintenance } from '@/store/global/globalSlice';
import { ReactNode } from 'react';
import s from './AppBaseLayout.module.less';

type Props = {
  children: ReactNode;
};

export const AppBaseLayout = ({ children }: Props) => {
  const isMaintenance = useAppSelector(selectMaintenance);
  const { isLoggedIn } = useAuth();
  const {
    config: { site },
  } = useAppContext();
  const { isOrderFormPage } = useOrderformFlags();

  return (
    <div className={s.wrapper}>
      <Header className={s.header} />
      <div className={s.main}>
        {isLoggedIn && <Sidebar className={s.sidebar} />}
        <div className={s.page}>
          <div className={s.content}>{isMaintenance ? <ErrorPage type={503} /> : children}</div>
          {!isOrderFormPage && (
            <footer className={s.footer}>
              <Container>
                <div className={s.footerContent}>
                  © {site.name} {new Date().getFullYear()}, v{APP_VERSION}. All rights reserved.
                </div>
              </Container>
            </footer>
          )}
        </div>
      </div>
    </div>
  );
};
